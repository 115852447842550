/**
 * 执行记录列表
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Select, Option, Message, Table, Page, Modal,Button,Poptip } from "view-design";
import { recordListColumns,
    recordStatus,
    tasksType,
    getRecordListService,
    delRecordListService,
    getRecordDataService,
} from '@/service/workflow-service'
import { mapMutations } from "vuex";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Poptip', Poptip);
import { downloadFile } from '@/utils/tools'
const RecordList = {
    name:'RecordList',
    data() {
        return {
            recordLoading:false, //loading
            tasksType:tasksType, //任务类型数据集
            recordListColumns:recordListColumns, //列表显示字段
            recordListArray:{}, //列表数据
            recordStatus:recordStatus, //执行状态
            deviceListHeight:0,
            recordListParams:{ //提交的数据集
                taskName:'',  //任务名称
                flowName:'', //引用流程
                status:'', //任务状态
                taskId:'',
                tasksType:'', //任务分类
                currentPage: 1,  //当前页
                displayNumber:Math.floor((window.innerHeight-211)/48) // 显示条数
            }
        }
    },
    created() {
        this.onRecordList().then()
        this.onTableHeight()
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_TASK_ID','SET_TASK_LIST']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 183
            this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.recordListParams.tasksName = ''
            this.recordListParams.flowName = ''
            this.recordListParams.status = ''
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onRecordList().then()
        },
        /**
         * 查看跳转
         * @param row
         */
        onQueryLink(row) {
            this.SET_TASK_ID(row.id)
            this.SET_TASK_LIST(row)
            this.$router.push('/workflow/record/info')
        },
        /**
         * 下载验证
         * @param row
         */
        onDownLoad(row) {
            this.onDownLoadRecordData(row).then()
        },
        /**
         * 删除
         * @param id
         */
        onDelete(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelRecordList(id).then()
                }
            });
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.recordListParams.currentPage = number
            this.onRecordList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.recordListParams.displayNumber = number
            this.onRecordList().then()
        },
        /**
         * 获取执行记录列表
         */
        async onRecordList() {
            try {
                this.recordLoading = true
                this.recordListArray = {}
                let params = {
                    taskName:this.recordListParams.taskName,
                    flowName:this.recordListParams.flowName,
                    status:this.recordListParams.status,
                    tasksType:this.recordListParams.tasksType,
                    taskId:this.recordListParams.taskId,
                    size:this.recordListParams.displayNumber,
                    page:this.recordListParams.currentPage
                }
                const { code,data } = await getRecordListService(params)
                if(code === 'SUCCESS'){
                    this.recordListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
        /**
         * 删除执行记录列表
         */
        async onDelRecordList(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await delRecordListService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    if(this.recordListArray.content.length === 1 && this.recordListArray.currentPage > 1){
                        this.recordListParams.currentPage=this.recordListParams.currentPage-1
                    }
                    this.onRecordList().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 下载记录数据
         */
        async onDownLoadRecordData(row) {
            try {
                this.recordLoading = true
                let params = {
                    id:row.id
                }
                const  data  = await getRecordDataService(params)
                downloadFile(row,data,'excel')
                this.recordLoading = false
            }catch (error){
                this.recordLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
        window.removeEventListener('resize',this.onTableHeight)
    }
}
export default RecordList
